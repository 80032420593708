import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

function SubscribeNewsletter() {
  const { t } = useTranslation();

  const [inputEmail, setInputEmail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  const handleEmailInput = (event) => {
    setInputEmail(event.target.value);
  };

  const handleBookAppointmentClick = () => {
    if (!isButtonDisabled) {
      const successMessage = t("subscribe.success");
      const errorMessage = t("subscribe.error");

      emailRegex.test(inputEmail)
        ? toast.success(successMessage, {
            position: "top-center",
            onOpen: () => {
              setIsButtonDisabled(true);
              setInputEmail("");
            },
            onClose: () => setIsButtonDisabled(false),
          })
        : toast.error(errorMessage, {
            position: "top-center",
            onOpen: () => setIsButtonDisabled(true),
            onClose: () => setIsButtonDisabled(false),
          });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleBookAppointmentClick();
    }
  };

  return (
    <div className="ft-info-p2">
      <input
        type="text"
        inputMode="email"
        className="ft-input"
        placeholder=  {t("footer.address")}
        name="email"
        value={inputEmail}
        onChange={handleEmailInput}
        autoComplete="true"
        onKeyPress={handleKeyPress}
        disabled={isButtonDisabled}
      />
      <button
        onClick={handleBookAppointmentClick}
        disabled={isButtonDisabled}
        style={{
          padding: "8px 24px",
          backgroundColor: "transparent",
          color: "white",
          borderRadius: "8px",
          border: "none",
          fontWeight: 500,
          transition: "background-color 0.2s",
          opacity: isButtonDisabled ? 0.5 : 1,
          cursor: isButtonDisabled ? "not-allowed" : "pointer",
        }}
      >
        
        {t("footer.button")}
      </button>
      <ToastContainer autoClose={4000} limit={1} closeButton={false} />
    </div>
  );
}

export default SubscribeNewsletter;
