import React from "react";
import { foods } from "../Scripts/reviews";
import "../Styles/Doctors.css";
import { useTranslation } from "react-i18next";


function Doctors() {
  const { t } = useTranslation();

  return (
    <div className="doctor-section" id="doctors">
      <div className="dt-title-content">
        <h3 className="dt-title">
          <span> {t("games.title")}</span>
        </h3>

        <p className="dt-description">
        {t("games.text")}
        </p>
      </div>

      <div className="dt-cards-content">
        {foods.map((food) => (
          <div className="dt-card" key={food.id}>
            <img src={food.img} alt={food.name} className="dt-card-img" />
            <p className="dt-card-name">{t(`etiquette.${food.id - 1}.name`)}</p>
            <p className="dt-card-title">{t(`etiquette.${food.id - 1}.title`)}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Doctors;
