import React from "react";
import "../Styles/Footer.css";
import SubscribeNewsletter from "./SubscribeNewsletter";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer({ user }) {
  const { t } = useTranslation();

  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <p className="ft-title">Hot Poker Room</p>
            <p className="ft-description">
              {user === "user" ? (
                <p>{t("footer.footer-text-mont")}</p>
              ) : (
                <p>{t("footer.footer-text")}</p>
              )}
            </p>
          </div>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">{t("footer.list-name")}</p>
          <ul className="ft-list-items">
            <li>
              <Link to="/about">{t("navbar.about")}</Link>
            </li>
            <li>
              <Link to="/etiquette">{t("navbar.etiquette")}</Link>
            </li>
            <li>
              <Link to="/terms-and-conditions">{t("navbar.terms")}</Link>
            </li>
            <li>
              <Link to={"/privacy-policy"}>
              {t("navbar.privacy")}
              </Link>
            </li>
          </ul>
        </div>

        <div className="ft-list" id="contact">
          <p className="ft-list-title"> {t("footer.footer-email")}</p>
          <SubscribeNewsletter />
        </div>
      </div>
    </div>
  );
}

export default Footer;
