import React from 'react';
import ReactLoading from 'react-loading';

const Loading = () => (
  <div>
    <ReactLoading type={'spin'} color={'#000'} height={50} width={50} />
  </div>
);

export default Loading;
