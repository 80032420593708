import React from "react";
import { useTranslation } from "react-i18next";


function NotFound() {
  const { t } = useTranslation();

  const deployedURL = window.location.href;


  return (
    <div style={{color:"white"}}>
      <h1>{t("notFound.title")}</h1>
      <p> {t("notFound.text")}  {deployedURL} </p>

    </div>
  );
}

export default NotFound;
