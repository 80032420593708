import React from "react";
import Hero from "../Components/Hero";
import Info from "../Components/Info";
import Loading from "../Components/Loading";


function Home({ user, loading }) {
  return (
    <>
      
      {loading ? <Loading /> : <Info user={user} />}
      {/* {user !== "user" ? <Hero /> : null} */}
     
    </>
  );
}

export default Home;
