import React from 'react';
import { useTranslation } from "react-i18next"
import '../Styles/LegalDocs.css';

function PrivacyAndCookies() {
  const { t } = useTranslation();

  return (
    <div className="legal-content-text" >
      <h1>{t("privacy.introduction.title")}</h1>
      <p className="legal-description">{t("privacy.introduction.description1")}</p>
      <p className="legal-description">{t("privacy.introduction.description2")}</p>
      <p className="legal-description">{t("privacy.introduction.description3")}</p>

      <h2>{t("privacy.toc.title")}</h2>
      <ul className="legal-description">
        {t("privacy.toc.items", { returnObjects: true }).map((item, index) => (
          <li key={index} className="legal-description">{item}</li>
        ))}
      </ul>

      <h2>{t("privacy.use_of_cookies.title")}</h2>
      <p className="legal-description">{t("privacy.use_of_cookies.description1")}</p>
      <p className="legal-description">{t("privacy.use_of_cookies.description2")}</p>
      <p className="legal-description">{t("privacy.use_of_cookies.description3")}</p>

      <h2>{t("privacy.about_cookies.title")}</h2>
      <p className="legal-description">{t("privacy.about_cookies.description1")}</p>
      <p className="legal-description">{t("privacy.about_cookies.description2")}</p>
      <p className="legal-description">{t("privacy.about_cookies.description3")}</p>

      <h2>{t("privacy.about_cookies.lifespan.title")}</h2>
      <div>
        <li className="legal-description">{t("privacy.about_cookies.lifespan.session")}</li>
        <li className="legal-description">{t("privacy.about_cookies.lifespan.persistent")}</li>
      </div>

      <h2>{t("privacy.about_cookies.domain.title")}</h2>
      <ul>
        <li className="legal-description">{t("privacy.about_cookies.domain.first_party")}</li>
        <li className="legal-description">{t("privacy.about_cookies.domain.third_party")}</li>
      </ul>

      <h2>{t("privacy.about_cookies.storage.title")}</h2>
      <ul>
        <li className="legal-description">{t("privacy.about_cookies.storage.browser_cookies")}</li>
        <li className="legal-description">{t("privacy.about_cookies.storage.flash_cookies")}</li>
      </ul>


      <h2>{t("privacy.cookies_we_use.title")}</h2>
      <p className="legal-description">{t("privacy.cookies_we_use.description")}</p>

      <h2>{t("privacy.essential_cookies.title")}</h2>
      <p className="legal-description">{t("privacy.essential_cookies.description1")}</p>
      <p className="legal-description">{t("privacy.essential_cookies.description2")}</p>

      <h2>{t("privacy.preference_cookies.title")}</h2>
      <p className="legal-description">{t("privacy.preference_cookies.description")}</p>

      <h2>{t("privacy.statistical_cookies.title")}</h2>
      <p className="legal-description">{t("privacy.statistical_cookies.description1")}</p>

      <h2>{t("privacy.marketing_cookies.title")}</h2>
      <p className="legal-description">{t("privacy.marketing_cookies.description1")}</p>
      <p className="legal-description">{t("privacy.marketing_cookies.description3")}</p>

      <h2>{t("privacy.third_party_cookies.title")}</h2>
      <p className="legal-description">{t("privacy.third_party_cookies.description")}</p>

      <h2>{t("privacy.change_settings.title")}</h2>
      <p className="legal-description">{t("privacy.change_settings.description1")}</p>
      <ul className="legal-description">
        {t("privacy.change_settings.ways", { returnObjects: true }).map((way, index) => (
          <li key={index}>{way}</li>
        ))}
      </ul>
      <p className="legal-description">{t("privacy.change_settings.description2")}</p>
      <p className="legal-description">{t("privacy.change_settings.description3")}</p>

      <h2>{t("privacy.third_party_services.title")}</h2>
      <p className="legal-description">{t("privacy.third_party_services.description")}</p>

      <h2>{t("privacy.browser_settings.title")}</h2>
      <p className="legal-description">{t("privacy.browser_settings.description")}</p>

      <h2>{t("privacy.flash_cookies.title")}</h2>
      <p className="legal-description">{t("privacy.flash_cookies.description1")}</p>
      <p className="legal-description">{t("privacy.flash_cookies.description2")}</p>

      <h2>{t("privacy.amendments.title")}</h2>
      <p className="legal-description">{t("privacy.amendments.description")}</p>
    </div>
  );
}

export default PrivacyAndCookies;
