import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import "../Styles/LegalDocs.css";

function LegalDocs() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div
      className="legal-content-text"
 
    >
      <h1 className="legal-title">{t('legalDocs.title')}</h1>
      <p className="legal-description">
        {t('legalDocs.description1')}
      </p>

      <h2>{t('legalDocs.useOfSiteTitle')}</h2>
      <p className="legal-description">
        {t('legalDocs.description2')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description3')}
      </p>

      <h2>{t('legalDocs.useOfWebsiteTitle')}</h2>
      <p className="legal-description">
        {t('legalDocs.description4')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description5')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description6')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description7')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description8')}
      </p>
 

      <ul>
      <li className="legal-description">{t('legalDocs.unauthorizedTitle')}</li>
      <li className="legal-description">
        {t('legalDocs.description9')}
      </li>
      <li className="legal-description">
        {t('legalDocs.description10')}
      </li>
      <li className="legal-description">
        {t('legalDocs.description11')}
      </li>
      <li className="legal-description">
        {t('legalDocs.description12')}
      </li>
      <li className="legal-description">
        {t('legalDocs.description13')}
      </li>
      <li className="legal-description">
        {t('legalDocs.description14')}
      </li>
      <li className="legal-description">{t('legalDocs.description15')}</li>
      <li className="legal-description">
        {t('legalDocs.description16')}
      </li>
      <li className="legal-description">
        {t('legalDocs.description17')}
      </li>
      </ul>
     
     

      <p className="legal-description">
        {t('legalDocs.description18')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description19')}
      </p>

      <h2>{t('legalDocs.registeredUsersTitle')}</h2>
      <p className="legal-description">
        {t('legalDocs.description20')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description21')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description22')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description23')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description24')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description25')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description26')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description27')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description28')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description29')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description30')}
      </p>

      <h2>{t('legalDocs.intellectualPropertyTitle')}</h2>
      <p className="legal-description">
        {t('legalDocs.description31')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description32')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description33')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description34')}
      </p>

      <h2>{t('legalDocs.contentProvidedByUsersTitle')}</h2>
      <p className="legal-description">
        {t('legalDocs.description35')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description36')}
      </p>
      <p className="legal-description">{t('legalDocs.description37')}</p>
      <li className="legal-description">
        {t('legalDocs.description38')}
      </li>
      <li className="legal-description">{t('legalDocs.description39')}</li>
      <li className="legal-description">
        {t('legalDocs.description40')}
      </li>
      <li className="legal-description">{t('legalDocs.description41')}</li>
      <li className="legal-description">{t('legalDocs.description42')}</li>
      <li className="legal-description">{t('legalDocs.description43')}</li>
      <li className="legal-description">{t('legalDocs.description44')}</li>

      <h2>{t('legalDocs.disclaimerTitle')}</h2>
      <p className="legal-description">
        {t('legalDocs.description45')}
      </p>
      <p className="legal-description">
        {t('legalDocs.description46')}
      </p>
    </div>
  );
}

export default LegalDocs;
