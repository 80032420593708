import React from "react";
import Doctor from "../Assets/0_0.webp";
import SolutionStep from "../Components/SolutionStep";
import "../Styles/About.css";
import { useTranslation } from "react-i18next";

function About({ user }) {
  const { t } = useTranslation();

  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
        <img src={Doctor} alt="Doctor Group" className="about-image1" />
      </div>

      <div className="about-text-content">
        <h3 className="about-title">
          <span> {t("navbar.about")}</span>
        </h3>
        <p className="about-description">
          {t("about.text")}
          {user === "user" ? t("about.first") : t("about.second")}{" "}
          {t("about.text-after")}
        </p>

        <h4 className="about-text-title">  {t("about.provide")}</h4>

        <SolutionStep
          title={t("about.solition01")}
          description={t("about.solDesc01")}
        />

        <SolutionStep
          title={t("about.solition02")}
    description={t("about.solDesc02")}
        />
      </div>
    </div>
  );
}

export default About;
